.App {
  text-align: center;
}

.message {
  
}


.contact-form {
  padding: 20px;
}

.message-name {

}

.message-email {

}

.button--container {
  margin-top: 20px;
}

.button button-primary {

}